<template lang="pug">
    .page-login__auth
      .page-login__auth-wrap
        h2.page-login__auth-title PROFEED PLANT
        p.page-login__auth-desc {{ 'pages.need_change_password.title' | translate }}
        form.page-login__auth-form(@submit.prevent="send")
          .page-login__auth-group
            input(
              type='password'
              :placeholder="$t('base.new_password')"
              v-model="form.password").input-field
          .page-login__auth-group
            input(
              type='password'
              :placeholder="$t('base.repeat_password')"
              v-model="form.password_confirmation").input-field
          v-btn.page-login__auth-btn(
            :loading="loading") {{ 'actions.save' | translate }}
          transition(name="fadex")
            .page-login__auth-alert(v-if="error")
              i.page-login__auth-icon.i-error
              p.page-login__auth-error {{ error }}
</template>

<script>
import LangSwitcher from '@/components/layouts/header/app-settings/LangSwitcher.vue'
import api from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'ChangePassword',

  components: {
    LangSwitcher
  },

  data: () => ({
    form: {
      password: null,
      password_confirmation: null
    },
    error: null,
    loading: false
  }),
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    send () {
      if (!this.user.user.need_change_pwd) {
        return false
      }
      api.auth.setNewPassword(this.form)
        .then(response => {
          if (!response.error) {
            this.$router.push('/login')
          }

          this.$toasted.show(response.data.error, {
            position: 'bottom-center',
            duration: 5000
          })
          for (const key in response.data.errors) {
            this.$toasted.show(response.data.errors[key], {
              position: 'bottom-center',
              duration: 5000
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/sass/abstracts/_variables.scss';

.app-settings {
  padding: 2rem;
  min-width: 19rem;

  &__title {
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 500;
    color: $color-gray;
    margin-bottom: 1.5rem;
  }

  &__section {
    position: relative;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $color-gray-light-2;
  }

  &__btn-icon {
    font-size: 1.8rem;
    margin-right: 1.3rem;
  }

  &__btn-text {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
}
</style>
