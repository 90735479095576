<template lang="pug">
  ul.lang-switcher
    li.lang-switcher__list(
      v-for="language in languages"
      :key="language.id"
      :class="{ 'lang-switcher__list--active': language.key === lang }"
      @click="onLangChange(language.key)")
      span.lang-switcher__list-icon
        font-awesome-icon(
          size="sm"
          icon="check")
      .lang-switcher__list-text {{ language.label }}

</template>

<script>
import { LANG_VAR_LOCAL_STORAGE_KEY } from '@/util/consts'

export default {
  name: 'LangSwitcher',

  data: () => ({
    lang: localStorage.getItem(LANG_VAR_LOCAL_STORAGE_KEY) || 'uk',
    languages: [
      { id: 0, label: 'Українська', key: 'uk' },
      { id: 1, label: 'Русский', key: 'ru' },
      { id: 3, label: 'English', key: 'en' },
      { id: 4, label: 'Poland', key: 'pl' }
    ]
  }),

  methods: {
    onLangChange (lang) {
      this.lang = lang
      this.$i18n.set(lang)
      localStorage.setItem(LANG_VAR_LOCAL_STORAGE_KEY, lang)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .lang-switcher {
    &__list {
      cursor: pointer;
      display: flex;
      margin-bottom: 1.3rem;
      padding-left: 3rem;
      transition: .3s ease;

      &:hover {
        color: $color-brand;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--active {
        .lang-switcher__list-icon {
          opacity: 1;
        }
        .lang-switcher__list-text {
          color: $color-brand;
        }
      }

      &-icon {
        opacity: 0;
        position: absolute;
        left: 0;
        font-size: 1.5rem;
        color: $color-brand;
      }

      &-text {
        font-size: 1.5rem;
        line-height: 1.8rem;
      }
    }
  }
</style>
